import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import socket from "../../config/socket";
import { login } from "../../services/auth";
import { setAffiliates } from "../../store/reducers/affiliateSlice";
import { setAuth, setUser } from "../../store/reducers/authSlice";
import { setBrands } from "../../store/reducers/brandSlice";
import { setStatuses } from "../../store/reducers/statusSlice";
import { getImageURL } from "../../helpers/image";
import Select from "../../components/UI/Select";
import { useTranslation } from "react-i18next";
import { languageCode } from "../../helpers/all";
import moment from "moment";

const Login = () => {
  const { t, i18n } = useTranslation();
  const affiliateData = useSelector((state) => state.affiliate);
  const [loading, setLoading] = useState(false);
  const optionsBrand = useSelector((state) => state.settings.brand);
  var cache = JSON.parse(localStorage.getItem("cache")) ?? [];
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({ mode: "all", reValidateMode: "onSubmit" });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      await login(data)
        .then((res) => {
          if (res?.brands?.length > 0) {
            var brandActive = res.brands.find((e) => e.main);
            localStorage.setItem("token", res.token);
            if (res?.member?.lang) {
              i18n.changeLanguage(languageCode(res.member.lang));
              moment.locale(languageCode(res.member.lang));
            }
            dispatch(setBrands(res.brands));
            var affiliates = [];
            var affiliatesActive = null;
            if (
              res?.member?.affiliateId === null ||
              !res?.member?.affiliateId
            ) {
              affiliates = [{ id: null, title: "Все" }, ...res.affiliates];

              if (!affiliateData?.active) {
                affiliatesActive = affiliates[0];
              }
            } else {
              affiliates = res.affiliates.filter(
                (e) => e.id === res.member.affiliateId
              );
              if (affiliates?.length > 0) {
                affiliates[0].main = true;
                if (!affiliateData?.active) {
                  affiliatesActive = affiliates[0];
                }
              }
            }

            dispatch(
              setAffiliates({ active: affiliatesActive, data: affiliates })
            );
            dispatch(setStatuses(res?.statuses ?? []));
            dispatch(setUser(res.member));
            dispatch(setAuth(true));

            data.title = brandActive.title;

            if (data?.login && cache && cache?.length > 0) {
              // Ищем объект в cache по id (предполагаем, что в объектах есть id)
              const existingItem = cache.find(
                (item) => item?.login === data?.login
              );
              // Проверяем, есть ли такой объект в cache
              if (!existingItem) {
                // Если объекта нет, добавляем его в cache
                cache.push(data);
              }
            } else {
              cache.push(data);
            }

            localStorage.setItem("cache", JSON.stringify(cache));

            if (brandActive?.id) {
              socket.io.opts.query = {
                brandId: brandActive.id,
                memberId: res.member.id,
              };
              socket.connect();
              socket.emit("member/add", {
                memberId: res.member.id,
              });
            }

            NotificationManager.success("Вы вошли в аккаунт");

            navigate("/");
          }
        })
        .catch((error) => {
          if (data?.login && cache && cache?.length > 0) {
            // Ищем объект в cache по id (предполагаем, что в объектах есть id)
            const existingItem = cache.find(
              (item) => item?.login === data?.login
            );
            // Проверяем, есть ли такой объект в cache
            if (existingItem) {
              // Если объекта нет, добавляем его в cache
              cache = cache.filter((item) => item?.login != data?.login);
              localStorage.setItem("cache", JSON.stringify(cache));
            }
          }

          NotificationManager.error(
            typeof error?.response?.data?.error == "string"
              ? error.response.data.error
              : "Неизвестная ошибка"
          );
        })
        .finally(() => setLoading(false));
    },
    [cache, affiliateData]
  );

  return (
    <>
      <Meta title="Войти" desc="Войти в профиль" />
      <Row className="gx-0 hv-100-important">
        <Col lg={8} md={7} className="login-info d-none d-md-flex">
          <div className="flex-column d-flex align-self-center justify-content-center align-items-center">
            <img
              src={
                optionsBrand?.logo
                  ? getImageURL({
                      path: optionsBrand.logo,
                      type: "all/brand/logo",
                      size: "full",
                    })
                  : "/logo.png"
              }
              height={80}
            />
            <h2 className="my-4 text-center">С возвращением!</h2>
            <img src="./images/auth/login.svg" width="80%" />
          </div>
        </Col>
        <Col lg={4} md={5}>
          <div className="login">
            <div className="login-form">
              <h3 className="mb-3 h5 fw-7 w-100">Войдите в свой профиль</h3>
              <p className="mb-4 text-muted fs-08">
                Нет профиля?{" "}
                <Link className="text-success" to="/reg">
                  Зарегистрироваться
                </Link>
              </p>
              {cache?.length > 0 && (
                <div className="mb-4">
                  <Select
                    label={t("Быстрый вход")}
                    placeholder={t("Выберите аккаунт")}
                    onClick={(e) => onSubmit(e)}
                    value={cache.login}
                    data={cache
                      .sort((a, b) => a.title - b.title)
                      .map((e) => ({
                        title: e.title,
                        subTitle: e.login,
                        ...e,
                      }))}
                  />
                </div>
              )}
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  autoFocus={true}
                  label="Логин"
                  name="login"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Введите логин",
                    maxLength: {
                      value: 250,
                      message: "Максимально 250 символов",
                    },
                  }}
                />
                <div className="mt-4">
                  <Input
                    label="Пароль"
                    type="password"
                    name="password"
                    errors={errors}
                    register={register}
                    validation={{
                      required: "Введите пароль",
                      minLength: {
                        value: 4,
                        message:
                          "Минимальный пароль должен состоять из 4-ех символов",
                      },
                    }}
                  />
                </div>
                <p className="mt-3 d-flex justify-content-end">
                  <Link to="/recovery" className="text-muted fs-08">
                    Забыли пароль?
                  </Link>
                </p>
                <Button
                  type="submit"
                  className="btn-primary mt-3 w-100"
                  disabled={!isValid}
                  isLoading={loading}
                >
                  Войти
                </Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Login;
