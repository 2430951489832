import React, { useCallback, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import ImageCropper from "../../components/Cropper";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { createParam } from "../../services/param";

const CreateParam = () => {
  const navigate = useNavigate();
  const [editImageParam, setEditImageParam] = useState({
    show: false,
    data: [],
  });
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      status: true,
    },
  });

  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    createParam(data)
      .then((res) => {
        NotificationManager.success("параметр успешно создан");
        navigate("/catalog/param/" + res.id);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  return (
    <>
      <Meta title="Создать параметр" />
      <div>
        <Link
          to="/catalog/categories"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать параметр</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <DragDropFile
                file={data.file}
                media={data.media}
                onChange={(e) =>
                  setEditImageParam((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              />
            </Col>
            <Col md={8}>
              <Row>
                <Col md={12}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.title}
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.desc ?? ""}
                      label="Описание"
                      name="desc"
                      rows={4}
                      onChange={(e) => setValue("desc", e)}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Тип"
                      value="checkbox"
                      data={[
                        { title: "Флажок", value: "checkbox" },
                        { title: "Выпадающий список", value: "select" },
                        { title: "Переключатель", value: "radio" },
                        { title: "Тег", value: "tag" },
                      ]}
                      onClick={(e) => setValue("type", e.value)}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.priority}
                      label="Порядок"
                      name="priority"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <Form.Check className="my-2">
                    <Form.Check.Input
                      type="checkbox"
                      name="status"
                      id="status"
                      defaultChecked={data.status}
                      {...register("status")}
                    />
                    <Form.Check.Label htmlFor="status" className="ms-2">
                      Показать
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ImageCropper
        file={editImageParam.data[0]}
        show={editImageParam.show}
        setShow={(e) => setEditImageParam((info) => ({ ...info, show: e }))}
        onComplete={(e) => {
          e && setValue("file", e.file);
          e && setValue("media", e.blob);
        }}
      />
    </>
  );
};

export default CreateParam;
