import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

const MenuOption = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  const menuItems =
    !user?.parent || user?.options?.access?.all
      ? [
          {
            label: t("Бренды"),
            path: "/options/brands",
          },
          {
            label: t("Документы"),
            path: "/options/documents",
            // submenu: [{ label: "Создать", path: "/options/documents/create" }],
          },
          {
            label: t("Сотрудники"),
            path: "/options/members",
            // submenu: [{ label: "Создать", path: "/options/members/create" }],
          },
          {
            label: t("Филиалы"),
            path: "/options/affiliates",
            submenu: [
              { label: t("Главная"), path: "/options/affiliates", end: true },
              { label: t("Города"), path: "/options/affiliates/cities" },
              { label: t("Зоны доставок"), path: "/options/affiliates/zones" },
              { label: t("Столы"), path: "/options/affiliates/tables" },
            ],
          },
          {
            label: t("Статусы"),
            path: "/options/statuses",
            // submenu: [{ label: "Создать", path: "/options/statuses/create" }],
          },
        ]
      : [
          {
            label: t("Сотрудники"),
            path: "/options/members",
            // submenu: [{ label: "Создать", path: "/options/members/create" }],
          },
          {
            label: t("Филиалы"),
            path: "/options/affiliates",
            submenu: [
              { label: t("Главная"), path: "/options/affiliates", end: true },
              { label: t("Города"), path: "/options/affiliates/cities" },
              { label: t("Зоны доставок"), path: "/options/affiliates/zones" },
              { label: t("Столы"), path: "/options/affiliates/tables" },
            ],
          },
        ];

  return (
    <>
      <div className="w-100 account-menu-sub-bg">
        <nav className="account-menu-sub">
          <ul>
            {menuItems.map((item) => (
              <li key={item.path}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  end={item.end}
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>

          {menuItems.map(
            (item) =>
              location.pathname.startsWith(item.path) &&
              item.submenu && (
                <ul>
                  {item.submenu.map((subitem) => (
                    <li key={subitem.path}>
                      <NavLink
                        to={subitem.path}
                        end={subitem.end}
                        className="btn-sm"
                        disabled={subitem.disabled}
                      >
                        {subitem.label}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )
          )}
        </nav>
      </div>
    </>
  );
};

// const MenuOption = () => {
//   const { t } = useTranslation();
//   return (
//     <div className="w-100 account-menu-sub-bg">
//       <nav className="account-menu-sub">
//         <ul>
//           <li>
//             <NavLink to="brands" end>
//               {t("Бренды")}
//             </NavLink>
//           </li>
//           <li>
//             <NavLink to="documents">{t("Документы")}</NavLink>
//           </li>
//           <li>
//             <NavLink to="affiliates">{t("Филиалы")}</NavLink>
//           </li>
//           <li>
//             <NavLink to="zones">{t("Зоны доставки")}</NavLink>
//           </li>
//           <li>
//             <NavLink to="members">{t("Сотрудники")}</NavLink>
//           </li>
//           <li>
//             <NavLink to="statuses">{t("Статусы")}</NavLink>
//           </li>
//         </ul>
//       </nav>
//     </div>
//   );
// };

export default MenuOption;
