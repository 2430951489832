import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import Input from "../../../components/UI/Input";
import Button from "../../../components/UI/Button";
import { Card, Col, Row } from "react-bootstrap";
import { IoRefreshOutline } from "react-icons/io5";
import { getStatistics } from "../../../services/statistic";
import {
  getEprCategory,
  getEprParam,
  getEprOrganizations,
  getEprProduct,
  getEprAll,
  getEprPrice,
} from "../../../services/sync";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import socket from "../../../config/socket";

const OneCForm = ({ data, onSubmit }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    // reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data ?? {
      login: null,
      password: null,
      url: null,
      organizations: [],
    },
  });

  const form = useWatch({ control });

  const [all, setAll] = useState(false);
  const [organizations, setOrganizations] = useState(false);

  const [statistic, setStatistic] = useState({
    loading: true,

    categories: 0,
    products: 0,
    prices: 0,
    params: 0,

    organizations: 0,
  });

  useLayoutEffect(() => {
    getStatistics({
      sync: true,
      category: true,
      price: true,
      product: true,
      param: true,
    })
      .then((res) => setStatistic({ ...statistic, ...res, loading: false }))
      .catch(() => setStatistic({ ...statistic, loading: false }));
  }, []);

  const clickGetErpProduct = useCallback(() => {
    getEprProduct(form);
  }, [form]);

  const clickGetErpPrice = useCallback(() => {
    getEprPrice(form);
  }, [form]);

  const clickGetErpParam = useCallback(() => {
    getEprParam(form);
  }, [form]);

  const clickGetErpCategory = useCallback(() => {
    getEprCategory(form);
  }, [form]);

  const clickGetErpOrganization = useCallback(() => {
    setOrganizations(true);
    getEprOrganizations(form)
      .then((res) => setValue("relationModule.options", res))
      .finally(() => setOrganizations(false));
  }, [form]);

  const clickGetErpAll = useCallback(() => {
    setAll(true);
    getEprAll(form)
      // .then((res) => setValue("relationModule.options", res))
      .finally(() => setAll(false));
  }, [form]);

  useEffect(() => {
    socket.on("module/onec", (data) => {
      if (data) {
        setValue("relationModule.options.status", data);
      }
    });

    return () => {
      socket.off("module/onec");
    };
  }, [brand]);

  return (
    <>
      <Card body className="mb-5">
        <h5 className="mb-4">Настройки</h5>
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Input
                label="Логин"
                placeholder="Введите логин"
                name="relationModule.options.login"
                errors={errors}
                defaultValue={form?.relationModule?.options?.login}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Input
                type="password"
                label="Пароль"
                placeholder="Введите пароль"
                name="relationModule.options.password"
                errors={errors}
                defaultValue={form?.relationModule?.options?.password}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <Input
                label="Ссылка на сервер"
                placeholder="Введите ссылку на сервер"
                name="relationModule.options.url"
                errors={errors}
                defaultValue={form?.relationModule?.options?.url}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить изменения
          </Button>
        </div>
      </Card>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <h5 className="fw-7">Синхронизация</h5>
        <Button
          isLoading={all}
          disabled={all || !form?.relationModule?.options?.login}
          onClick={() => clickGetErpAll()}
          className="btn btn-sm btn-default text-gray"
        >
          <IoRefreshOutline size={16} className="text-gray me-2" />
          Выгрузить все
        </Button>
      </div>
      <Row>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Категории</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.category?.status ==
                    "process"
                }
                onClick={() => clickGetErpCategory()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.category?.status ||
                form?.relationModule?.options?.status?.category?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.category?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.categories}
            </h2>
            <div className="mt-2">
              <Link
                to="/catalog/categories"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Перейти к списку
              </Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Товары</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.product?.status ==
                    "process"
                }
                onClick={() => clickGetErpProduct()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.product?.status ||
                form?.relationModule?.options?.status?.product?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.product?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.products}
            </h2>
            <div className="mt-2">
              <Link
                to="/catalog/products"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Перейти к списку
              </Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Цены и остатки</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.price?.status ==
                    "process"
                }
                onClick={() => clickGetErpPrice()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.price?.status ||
                form?.relationModule?.options?.status?.price?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.price?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.prices}
            </h2>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Параметры</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.param?.status ==
                    "process"
                }
                onClick={() => clickGetErpParam()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.param?.status ||
                form?.relationModule?.options?.status?.param?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.param?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.params}
            </h2>
            <div className="mt-2">
              <Link
                to="/catalog/params"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Перейти к списку
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Row>
        <Col md={6}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h5 className="mb-2 fw-6 h6">Webhook</h5>
              <Button
                disabled={!form?.relationModule?.options?.webhook?.accounting}
                // onClick={() => clickGetErpParam()}
                className="btn btn-sm btn-default"
              >
                <IoRefreshOutline size={22} className="text-gray" />
              </Button>
            </div>
            <div className="mb-3">
              <Input
                label="Статусы заказа"
                name="relationModule.options.webhook.accounting"
                readOnly={false}
                errors={errors}
                defaultValue={
                  form?.relationModule?.options?.webhook?.accounting ??
                  `${process.env.REACT_APP_BASE_URL}/webhook/accounting/${
                    brand?.id ?? ""
                  }/`
                }
                register={register}
              />
            </div>
            <div className="mb-3">
              <Input
                label="Стоп меню"
                name="relationModule.options.webhook.stop"
                readOnly={false}
                errors={errors}
                defaultValue={
                  form?.relationModule?.options?.webhook?.stop ??
                  `${process.env.REACT_APP_BASE_URL}/webhook/stop/${
                    brand?.id ?? ""
                  }/`
                }
                register={register}
              />
            </div>
          </Card>
        </Col>
      </Row> */}

      <h5 className="my-4 fw-7">Данные для заказа</h5>
      <Row>
        <Col md={4}>
          <Row>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Организации</h5>
                  <Button
                    isLoading={organizations}
                    disabled={
                      organizations || !form?.relationModule?.options?.login
                    }
                    onClick={() => clickGetErpOrganization()}
                    className="btn btn-sm btn-default"
                  >
                    <IoRefreshOutline size={22} className="text-gray" />
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {form?.relationModule &&
                  Array.isArray(form?.relationModule?.options?.organizations)
                    ? form.relationModule.options.organizations.length
                    : 0}
                </h2>
                <div className="body-scroll">
                  {form?.relationModule &&
                    Array.isArray(
                      form?.relationModule?.options?.organizations
                    ) &&
                    form?.relationModule?.options?.organizations &&
                    form?.relationModule?.options?.organizations.map(
                      (item, index) => (
                        <div className="mt-3 d-flex align-items-center">
                          <p className="fs-07 text-muted">{item.id}</p>
                          <p className="fs-09">
                            <span>{item.name}</span>
                            <span>{item.orderServiceType}</span>
                          </p>
                        </div>
                      )
                    )}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        {/* <Col md={4}>
          <Row>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Типы оплаты</h5>
                  <Button
                    isLoading={typesPayment}
                    disabled={
                      typesPayment || !form?.relationModule?.options?.login
                    }
                    onClick={() => clickGetErpTypesPayment()}
                    className="btn btn-sm btn-default"
                  >
                    <IoRefreshOutline size={22} className="text-gray" />
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {form?.relationModule?.options?.typesPayment?.length ?? 0}
                </h2>
                <div className="body-scroll">
                  {form?.relationModule?.options?.typesPayment &&
                    form?.relationModule?.options?.typesPayment.map((item) => (
                      <>
                        <p className="mt-3 fs-07 text-muted d-flex justify-content-between">
                          {item.id}
                        </p>
                        <p className="fs-09 d-flex justify-content-between">
                          <span>{item.name}</span>
                          <span>{item.code}</span>
                        </p>
                      </>
                    ))}
                </div>
              </Card>
            </Col>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Терминалы</h5>
                  <Button
                    isLoading={terminal}
                    disabled={terminal || !form?.relationModule?.options?.login}
                    onClick={() => clickGetErpTerminal()}
                    className="btn btn-sm btn-default"
                  >
                    <IoRefreshOutline size={22} className="text-gray" />
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {Array.isArray(form?.relationModule?.options?.terminals) &&
                  form?.relationModule?.options?.terminals?.length
                    ? form.relationModule.options.terminals.length
                    : 0}
                </h2>
                <div className="body-scroll">
                  {Array.isArray(form?.relationModule?.options?.terminals) &&
                    form?.relationModule?.options?.terminals?.length > 0 &&
                    form.relationModule.options.terminals.map((item) => {
                      return (
                        <>
                          <p className="mt-3 fs-07 text-muted d-flex justify-content-between">
                            Организация: {item.organizationId}
                          </p>
                          {item?.items?.length > 0 &&
                            item.items.map((item) => (
                              <>
                                <p className="mt-2 fs-09 d-flex justify-content-between">
                                  <span>{item.name}</span>
                                  <span>{item.address}</span>
                                </p>
                                <p className="fs-07 text-muted d-flex justify-content-between">
                                  Терминал: {item.id}
                                </p>
                              </>
                            ))}
                        </>
                      );
                    })}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Скидки</h5>
                  <Button
                    isLoading={discounts}
                    disabled={
                      organizations || !form?.relationModule?.options?.login
                    }
                    onClick={() => clickGetErpDiscount()}
                    className="btn btn-sm btn-default"
                  >
                    <IoRefreshOutline size={22} className="text-gray" />
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {form?.relationModule &&
                  Array.isArray(form?.relationModule?.options?.discounts)
                    ? form.relationModule.options.discounts.length
                    : 0}
                </h2>
                <div className="body-scroll">
                  {form?.relationModule &&
                    Array.isArray(form?.relationModule?.options?.discounts) &&
                    form?.relationModule?.options?.discounts &&
                    form?.relationModule?.options?.discounts.map((item) => (
                      <>
                        <p className="mt-3 fs-07 text-muted d-flex justify-content-between">
                          {item.id}
                        </p>
                        <p className="fs-09 d-flex justify-content-between">
                          <span>{item.name}</span>
                          <span>{item.orderServiceType}</span>
                        </p>
                      </>
                    ))}
                </div>
              </Card>
            </Col>
            <Col md={12}>
              <Card body className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <h5 className="mb-2 fw-6 h6">Типы доставки</h5>
                  <Button
                    isLoading={typesDelivery}
                    disabled={
                      typesDelivery || !form?.relationModule?.options?.login
                    }
                    onClick={() => clickGetErpTypesDelivery()}
                    className="btn btn-sm btn-default"
                  >
                    <IoRefreshOutline size={22} className="text-gray" />
                  </Button>
                </div>
                <h2 className="m-0 d-flex align-items-center">
                  {Array.isArray(
                    form?.relationModule?.options?.typesDelivery
                  ) &&
                  form?.relationModule?.options?.typesDelivery[0]?.items
                    ?.length > 0
                    ? form.relationModule.options.typesDelivery[0].items?.length
                    : 0}
                </h2>
                <div className="body-scroll">
                  {Array.isArray(
                    form?.relationModule?.options?.typesDelivery
                  ) &&
                    form?.relationModule?.options?.typesDelivery[0]?.items &&
                    form?.relationModule?.options?.typesDelivery[0]?.items.map(
                      (item) => (
                        <>
                          <p className="mt-3 fs-07 text-muted d-flex justify-content-between">
                            {item.id}
                          </p>
                          <p className="fs-09 d-flex justify-content-between">
                            <span>{item.name}</span>
                            <span>{item.orderServiceType}</span>
                          </p>
                        </>
                      )
                    )}
                </div>
              </Card>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
};
export default OneCForm;
