import React, { useCallback, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import Select from "../../../components/UI/Select";
import { getYandexTariff } from "../../../services/sync";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";

const YandexDeliveryForm = ({ data, onSubmit }) => {
  const affiliates = useSelector((state) => state.affiliate.data);
  const [loadingTariff, setLoadingTariff] = useState(false);

  const {
    control,
    setValue,
    register,
    reset,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  const onGetTariff = useCallback(() => {
    setLoadingTariff(true);
    getYandexTariff(form)
      .then((res) => reset(res))
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      })
      .finally(() => setLoadingTariff(false));
  }, [form]);

  return (
    <Card body className="mb-4">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span>
          <h5 className="mb-3">Настройки</h5>
        </span>
      </div>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Input
              label="Токен"
              placeholder="Введите токен"
              name="relationModule.options.token"
              errors={errors}
              defaultValue={form?.relationModule?.options?.token}
              register={register}
              validation={{ required: "Обязательное поле" }}
            />
            <p className="text-muted fs-08">
              Токен нужно получить в аккаунте Яндекс Доставки в разделе
              Интеграции по API
            </p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <h5 className="mb-2 mt-3">Размеры по умолчанию</h5>
          <p className="text-muted fs-08 mb-2">
            Если размеры не были заданы, заказ оформляется с учетом максимально
            допустимых размеров для выбранного метода доставки
          </p>
          <div className="box box-gray mb-4 fs-08">
            <p>
              Если фактические характеристики товара превысят допустимые, курьер
              вправе отказаться от выполнения такого заказа на месте. В этом
              случае будет удержана стоимость подачи.
            </p>
            <br />
            <p>
              <b>Курьер</b>: до 0.80 м × 0.50 м × 0.50 м
            </p>
            <p>
              <b>Экспресс</b>: до 1.00 м × 0.60 м × 0.50 м
            </p>
            <p>
              <b>Грузовой</b>:
            </p>
            <p>Маленький кузов: до 1.70 м × 0.96 м × 0.90 м</p>
            <p>Средний кузов: до 2.60 м × 1.30 м × 1.50 м</p>
            <p>Большой кузов: до 3.80 м × 1.80 м × 1.80 м</p>
          </div>
          <Row className="gx-2">
            <Col lg={4}>
              <div className="mb-3">
                <Input
                  label="Длина(м)"
                  placeholder="0.8 (80см)"
                  name="relationModule.options.size.length"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.size?.length}
                  register={register}
                />
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Input
                  label="Ширина(м)"
                  placeholder="0.8 (80см)"
                  name="relationModule.options.size.width"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.size?.width}
                  register={register}
                />
              </div>
            </Col>
            <Col lg={4}>
              <div className="mb-3">
                <Input
                  label="Высота(м)"
                  placeholder="0.8 (80см)"
                  name="relationModule.options.size.height"
                  errors={errors}
                  defaultValue={form?.relationModule?.options?.size?.height}
                  register={register}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <h5 className="mb-2 mt-3">Тарифы</h5>
          <p className="text-muted fs-08 mb-2">
            У вас пока нет ни одного тарифа. Выгрузите данные из аккаунта Яндекс
            Доставки.
          </p>
          <div className="mb-3">
            <Select
              label="Филиал"
              onClick={(e) => setValue("affiliateId", e.value)}
              value={form?.affiliateId}
              data={affiliates.filter(e => e.id !== null).map((e) => ({ title: e.full, value: e.id }))}
            />
          </div>
          <Button isLoading={loadingTariff} onClick={() => onGetTariff()}>
            Начать выгрузку
          </Button>
        </Col>
      </Row>
      <h5 className="mb-2 mt-3">Требования к доставке</h5>
      <p className="text-muted fs-08 mb-4">
        Если опции не были заданы, заказ оформляется с настройками по умолчанию
      </p>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Select
              label="Вид доставки"
              onClick={(e) =>
                setValue(
                  "relationModule.options.requirements.taxi_classes",
                  e.value
                )
              }
              value={
                form?.relationModule?.options?.requirements?.taxi_classes ??
                "courier"
              }
              data={[
                { title: "Курьер", value: "courier" },
                { title: "Экспресс", value: "express" },
                { title: "Грузовой", value: "cargo" },
              ]}
            />
          </div>
        </Col>
        {form?.relationModule?.options?.requirements?.taxi_classes ===
          "cargo" && (
          <Col lg={4}>
            <div className="mb-3">
              <Select
                label="Тип (размер) кузова"
                onClick={(e) =>
                  setValue(
                    "relationModule.options.requirements.cargo_type",
                    e.value
                  )
                }
                value={
                  form?.relationModule?.options?.requirements?.cargo_type ??
                  "lcv_m"
                }
                data={[
                  { title: "Маленький кузов", value: "van" },
                  { title: "Средний кузов", value: "lcv_m" },
                  { title: "Большой кузов", value: "lcv_l" },
                ]}
              />
            </div>
          </Col>
        )}
        {form?.relationModule?.options?.requirements?.taxi_classes ===
          "cargo" && (
          <Col lg={4}>
            <div className="mb-3">
              <Input
                label="Кол-во грузчиков для грузового типа"
                placeholder="1"
                type="number"
                name="relationModule.options.requirements.cargo_loaders"
                errors={errors}
                defaultValue={
                  form?.relationModule?.options?.requirements?.cargo_loaders
                }
                register={register}
              />
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <Form.Check className="d-inline-block me-3 mb-2 mt-2">
            <Form.Check.Input
              disabled
              type="checkbox"
              name="relationModule.options.requirements.pro_courier"
              id="pro_courier"
              defaultChecked={
                form?.relationModule?.options?.requirements?.pro_courier
              }
              {...register("relationModule.options.requirements.pro_courier")}
            />
            <Form.Check.Label
              title="Временно недоступно в России"
              htmlFor="pro_courier"
              className="ms-2 d-flex flex-column"
            >
              <p className="fs-09">
                Включить опцию "Профи" для тарифов "Экспресс" и "Курьер"
              </p>
              <p className="fs-08 text-muted">
                Поиск исполнителя будет происходить только среди опытных
                курьеров
              </p>
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="d-inline-block me-3 mb-2 mt-2">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.requirements.skip_door_to_door"
              id="skip_door_to_door"
              defaultChecked={
                form?.relationModule?.options?.requirements?.skip_door_to_door
              }
              {...register(
                "relationModule.options.requirements.skip_door_to_door"
              )}
            />
            <Form.Check.Label
              htmlFor="skip_door_to_door"
              className="ms-2 d-flex flex-column"
            >
              <p className="fs-09">
                Отключить доставку до двери (выключить опцию "От двери до
                двери")
              </p>
              <p className="fs-08 text-muted">
                Курьер доставит заказ только на улицу, до подъезда
              </p>
            </Form.Check.Label>
          </Form.Check>
          <h5 className="mb-3 mt-3">Дополнительные опции</h5>
          <Form.Check className="d-inline-block me-3 mb-2 mt-2">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.requirements.cargo_options"
              value="auto_courier"
              id="cargo_options_1"
              defaultChecked={
                form?.relationModule?.options?.requirements?.cargo_options
              }
              {...register("relationModule.options.requirements.cargo_options")}
            />
            <Form.Check.Label
              htmlFor="cargo_options_1"
              className="ms-2 d-flex flex-column"
            >
              Курьер только на автомобиле
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="d-inline-block me-3 mb-2 mt-2">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.requirements.cargo_options"
              value="thermobag"
              id="cargo_options_2"
              defaultChecked={
                form?.relationModule?.options?.requirements?.cargo_options
              }
              {...register("relationModule.options.requirements.cargo_options")}
            />
            <Form.Check.Label
              htmlFor="cargo_options_2"
              className="ms-2 d-flex flex-column"
            >
              Курьер с термосумкой
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default YandexDeliveryForm;
