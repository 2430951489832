import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getProducts = async (data) => {
  const response = await $authApi.get(apiRoutes.PRODUCTS, {
    params: data,
  });

  return response?.data;
};

const getProductList = async (data) => {
  const response = await $authApi.get(apiRoutes.PRODUCT_LIST, {
    params: data,
  });

  return response?.data;
};

const getProductDownload = async (type) => {
  const response = await $authApi.get(apiRoutes.PRODUCT_DOWNLOAD, {
    params: { type },
    responseType: "blob",
  });

  return response;
};
const getProduct = async (id) => {
  const response = await $authApi.get(apiRoutes.PRODUCT, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createProduct = async (data) => {
  const response = await $authApi.post(apiRoutes.PRODUCT_CREATE, data);
  return response?.data;
};

const editProduct = async (data) => {
  const response = await $authApi.post(apiRoutes.PRODUCTS, data);
  return response?.data;
};

const deleteProduct = async (ids) => {
  const response = await $authApi.delete(apiRoutes.PRODUCTS, {
    data: { ids },
  });
  return response?.data;
};

const copyProduct = async (data) => {
  const response = await $authApi.post(apiRoutes.PRODUCT_COPY, data);
  return response?.data;
};

const deleteProductAll = async () => {
  const response = await $authApi.delete(apiRoutes.PRODUCTS_DELETE_ALL);
  return response?.data;
};

const addProductParam = async (data) => {
  const response = await $authApi.post(apiRoutes.PRODUCT_ADD_PARAM, data);
  return response?.data;
};

const deleteProductParam = async (id) => {
  const response = await $authApi.delete(apiRoutes.PRODUCT_DELETE_PARAM, {
    data: { id },
  });
  return response?.data;
};

const updateDataProduct = async (data) => {
  const response = await $authApi.post(apiRoutes.PRODUCT_UPDATE_DATA, data);
  return response?.data;
};


export {
  updateDataProduct,
  getProducts,
  getProduct,
  createProduct,
  editProduct,
  deleteProduct,
  copyProduct,
  deleteProductAll,
  addProductParam,
  deleteProductParam,
  getProductList,
  getProductDownload,
};
