const moduleRatePrice = ({ module, relationModule, brand }) => {
  const moduleSettings = brand?.relationRate?.rate?.name;
  const settings =
    moduleSettings && typeof module.price === "object"
      ? module.price[moduleSettings]
      : module.price;

  var value = 0;

  if (Array.isArray(settings) && settings?.length === 1) {
    value = Number(settings[0]?.price) > 0 ? settings[0].price + " ₽" : 0;
  } else if (
    Array.isArray(settings) &&
    settings?.length > 0 &&
    settings.find((e) => e.type === brand?.relationRate?.rate?.type)?.price
  ) {
    let data = settings.find((e) => e.type === brand?.relationRate?.rate?.type);
    value =
      data?.price > 0
        ? data?.price + (data?.type === "month" ? " ₽/мес" : " ₽")
        : 0;
  }

  const status = !!relationModule?.end;

  value = status ? "Подключено" : value ? value : "Бесплатно";

  return { status, value };
};

const moduleBgColor = {
  month: "primary",
  one: "primary",
  soon: "danger",
  free: "secondary",
};

export { moduleRatePrice, moduleBgColor };
