import React, { useCallback, useEffect } from "react";
import { Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import CustomEditor from "../../components/editor/CustomEditor";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import { generateUrl } from "../../helpers/all";
import { createBlog } from "../../services/blog";

const BlogCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const affiliate = useSelector((state) => state.affiliate.data);

  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    createBlog(formData)
      .then(() => {
        NotificationManager.success("Новость успешно создана");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  useEffect(() => {
    setValue("alias", generateUrl(data.title));
  }, [data.title]);

  return (
    <>
      <Meta title="Создать новость" />
      <div>
        <Link
          to="/content/blogs"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать новость</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Tabs className="mb-3" fill>
            <Tab.Pane eventKey={0} title={t("Основное")}>
              <Row className="d-flex">
                <Col md={4} className="pb-3">
                  <div className="mb-3">
                    <DragDropFile
                      file={data.file}
                      onChange={(e) => setValue("file", e)}
                      onDelete={(e) => setValue("file", e.file)}
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      autoFocus={true}
                      label="Заголовок"
                      name="title"
                      placeholder="Введите заголовок"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Input
                      defaultValue={data?.alias ?? generateUrl(data.title)}
                      label="Ссылка"
                      name="alias"
                      placeholder="Введите ссылку"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Введите ссылку",
                        minLength: {
                          value: 2,
                          message: "Минимально 2 символа",
                        },
                        maxLength: {
                          value: 255,
                          message: "Максимально 255 символов",
                        },
                        pattern: {
                          value: /^[a-z0-9_]+$/,
                          message: "Неверный формат (Только a-z0-9_)",
                        },
                      }}
                    />
                  </div>
                  <CustomEditor
                    content={data.content}
                    onChange={(e) => setValue("content", e)}
                  />
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey={1} title={t("Филиалы")}>
              <p className="mb-3 text-muted fs-09">
                Если ни один филиал не выбран, баннер будет показан во всех
                филиалах.
              </p>
              {affiliate?.length > 0 && (
                <>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="options.affiliateView"
                      checked={data?.options?.affiliateView}
                      id="affiliate_view"
                      onChange={() => {
                        setValue(
                          "options.affiliateView",
                          !!!data?.options?.affiliateView
                        );
                        if (data?.options?.affiliateView) {
                          setValue("relationBlogs", []);
                        }
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="affiliate_view"
                      className="ms-2 fs-09"
                    >
                      Включить показ по филиалам
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      disabled={!data?.options?.affiliateView}
                      checked={
                        affiliate?.length > 0 &&
                        affiliate?.length === data?.relationBlogs?.length
                      }
                      id="affiliate_all"
                      onChange={() => {
                        if (
                          affiliate?.length > 0 &&
                          affiliate?.length === data?.relationBlogs?.length
                        ) {
                          setValue("relationBlogs", []);
                        } else {
                          setValue(
                            "relationBlogs",
                            affiliate.map((e) => ({
                              affiliateId: e.id,
                            }))
                          );
                        }
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="affiliate_all"
                      className="ms-2 fs-09"
                    >
                      Выделить все
                    </Form.Check.Label>
                  </Form.Check>
                  <hr />
                </>
              )}
              {affiliate?.length > 0 &&
                affiliate.map((e) => {
                  let option =
                    data?.relationBlogs?.length > 0 &&
                    data.relationBlogs.find(
                      (item) => item.affiliateId === e.id
                    );

                  return (
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Form.Check>
                        <Form.Check.Input
                          type="checkbox"
                          name="relationBlogs"
                          id={"affiliate_" + e.id}
                          disabled={!data?.options?.affiliateView}
                          checked={!!option}
                          onChange={() => {
                            if (!!option) {
                              let newArray = data.relationBlogs.filter(
                                (item) => item.affiliateId != e.id
                              );
                              setValue("relationBlogs", newArray);
                            } else {
                              let newArray =
                                data?.relationBlogs?.length > 0
                                  ? [
                                      ...data.relationBlogs,
                                      {
                                        affiliateId: e.id,
                                      },
                                    ]
                                  : [
                                      {
                                        affiliateId: e.id,
                                      },
                                    ];
                              setValue("relationBlogs", newArray);
                            }
                          }}
                        />
                        <Form.Check.Label
                          htmlFor={"affiliate_" + e.id}
                          className="ms-2 fs-09"
                        >
                          {e?.title ? e.title : e.full}
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  );
                })}
            </Tab.Pane>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default BlogCreate;
