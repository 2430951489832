import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { IoSettingsOutline } from "react-icons/io5";
import { memo } from "react";
import { useForm } from "react-hook-form";
import { Offcanvas } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Button from "./UI/Button";
import { editBrand } from "../services/brand";
import { NotificationManager } from "react-notifications";
import { updateBrand } from "../store/reducers/brandSlice";
import { updateDataProduct } from "../services/product";

const MenuCatalog = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brand = useSelector((state) => state.brand.active);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [settings, setSettings] = useState(false);
  const { register, handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: brand,
  });

  const onSubmit = useCallback(
    (data) => {
      setLoadingSave(true);
      editBrand(data)
        .then((res) => {
          NotificationManager.success("Бренд успешно изменен");
          if (res) {
            dispatch(updateBrand(res));
          }
        })
        .catch((error) => {
          NotificationManager.error(
            typeof error?.response?.data?.error == "string"
              ? error.response.data.error
              : "Неизвестная ошибка"
          );
        })
        .finally(() => setLoadingSave(false));
    },
    [loadingSave]
  );

  const onUpdateDataProduct = useCallback(
    (data) => {
      setLoading(true);
      updateDataProduct(data)
        .then((res) => {
          NotificationManager.success("Данные по каталогу успешно обновлены");
        })
        .catch((error) => {
          NotificationManager.error(
            typeof error?.response?.data?.error == "string"
              ? error.response.data.error
              : "Неизвестная ошибка"
          );
        })
        .finally(() => setLoading(false));
    },
    [loading]
  );

  return (
    <>
      <div className="w-100 account-menu-sub-bg d-flex justify-content-between">
        <nav className="account-menu-sub">
          <ul>
            <li>
              <NavLink to="categories" end>
                {t("Категории")}
              </NavLink>
            </li>
            <li>
              <NavLink to="products">{t("Товары")}</NavLink>
            </li>
            <li>
              <NavLink to="additions">{t("Добавки")}</NavLink>
            </li>
            <li>
              <NavLink to="modifiers">{t("Модификаторы")}</NavLink>
            </li>
            {(brand?.type === "shop" || brand?.type == "service") && (
              <li>
                <NavLink to="params">{t("Параметры")}</NavLink>
              </li>
            )}
            <li>
              <NavLink to="wishes">{t("Пожелания")}</NavLink>
            </li>
            {/* <li>
              <NavLink to="storages" disabled>
                {t("Склад")}
              </NavLink>
            </li> */}
          </ul>
        </nav>
        <div>
          <a className="btn btn-light" onClick={() => setSettings(true)}>
            <IoSettingsOutline size={20} />
          </a>
        </div>
      </div>
      <Offcanvas
        show={settings}
        onHide={setSettings}
        placement="end"
        name="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Настройки</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column justify-content-between">
          <div>
            <div className="box box-gray">
              <h5 className="mb-3">Товары и модификаторы</h5>
              <Form.Check className="my-2">
                <Form.Check.Input
                  type="radio"
                  value="one"
                  id="priceAffiliateType-one"
                  {...register("options.priceAffiliateType")}
                />
                <Form.Check.Label
                  htmlFor="priceAffiliateType-one"
                  className="ms-2 d-flex flex-column"
                >
                  Разное меню
                  <p className="fs-07 text-muted">
                    Цены и ассортимент показаны по филиалам
                  </p>
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="my-2">
                <Form.Check.Input
                  type="radio"
                  value="all"
                  id="priceAffiliateType-all"
                  {...register("options.priceAffiliateType")}
                />
                <Form.Check.Label
                  htmlFor="priceAffiliateType-all"
                  className="ms-2 d-flex flex-column"
                >
                  Единое меню
                  <p className="fs-07 text-muted">
                    Цены и ассортимент одинаковый по всем филиалам
                  </p>
                </Form.Check.Label>
              </Form.Check>
              <Button
                disabled={!!!brand?.options?.priceAffiliateType}
                isLoading={loading}
                className="w-100 mt-3 btn-dark"
                onClick={() => handleSubmit(onUpdateDataProduct())}
              >
                Обновить данные
              </Button>
            </div>
          </div>
          <div>
            <Button
              isLoading={loadingSave}
              className="w-100 btn-primary"
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить измнения
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
});

export default MenuCatalog;
