import React from "react";
import {
  IoChevronBackOutline,
  IoChevronForwardCircleOutline,
} from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Info from "../../components/UI/Info";
import faqData from "./faqData.json";

const FaqCategory = () => {
  const { categoryId } = useParams();
  const data = faqData.find((e) => e.id === categoryId);

  if (!data?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такой новости нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={data?.title ?? "Категория"} />
      <div>
        <Link
          to="/faq"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h1 className="mb-4">{data.title}</h1>
      {data?.data?.length > 0 &&
        data.data.map((e) => (
          <Link
            to={"/faq/" + data.id + "/" + e.id}
            className="box box-gray d-flex flex-row align-items-center justify-content-between"
          >
            <div>
              <h3 className="mb-2">{e.title}</h3>
              <p className="text-muted fs-09">Добавлено {e.date}</p>
              {e.desc && (
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{ __html: e.desc }}
                />
              )}
            </div>
            <div className="pe-2">
              <IoChevronForwardCircleOutline size={30} color="#999" />
            </div>
          </Link>
        ))}
    </>
  );
};

export default FaqCategory;
