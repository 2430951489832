import React, { useCallback } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import DragDropFile from "../../../components/DragDropFile";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import Textarea from "../../../components/UI/Textarea";
import { getImageURL } from "../../../helpers/image";
import { editModuleShowcase } from "../../../services/module";

const DeliveryPointForm = ({ data }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data,
  });

  const form = useWatch({ control });

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.logo) {
      for (let file of data.logo) {
        formData.append("logo", file);
      }
    }
    if (data?.bg) {
      for (let file of data.bg) {
        formData.append("bg", file);
      }
    }

    editModuleShowcase(formData)
      .then(() => {
        NotificationManager.success("Изменения успешно добавлены");
      })
      .catch(
        (err) => err && NotificationManager.error("Ошибка при сохранении")
      );
  }, []);

  return (
    <Card body className="mb-4">
      <h3 className="mb-4 fw-7">Настройки</h3>

      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Input
              label="Название"
              placeholder="Введите название"
              name="relationModule.options.title"
              errors={errors}
              defaultValue={form?.relationModule?.options?.title}
              register={register}
              validation={{ required: "Обязательное поле" }}
            />
          </div>
          <div className="mb-3">
            <Textarea
              placeholder="Введите описание"
              name="relationModule.options.desc"
              errors={errors}
              register={register}
              rows={6}
              validation={{
                maxLength: {
                  value: 500,
                  message: "Максимально 500 символов",
                },
              }}
              defaultValue={form?.relationModule?.options?.desc}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <div className="mb-3">
            <DragDropFile
              col={12}
              title="Лого для светлой версии"
              desc=" "
              file={form.logo}
              onChange={(e) => setValue("logo", e)}
              onDelete={(e) => setValue("logo", e.file)}
            />
          </div>
          {form?.relationModule?.options?.logo && (
            <div className="mb-3 bg-light border rounded-3 p-3 justify-content-center d-flex">
              <img
                src={getImageURL({
                  path: form?.relationModule?.options?.logo,
                  type: "showcase/brand/logo",
                  size: "full",
                })}
                width="100%"
              />
            </div>
          )}
        </Col>
        <Col md={4}>
          <div className="mb-3">
            <DragDropFile
              col={12}
              title="Выберите фон"
              desc=" "
              file={form.bg}
              maxAspect={1}
              onChange={(e) => setValue("bg", e)}
              onDelete={(e) => setValue("bg", e.file)}
            />
          </div>
          {form?.relationModule?.options?.bg && (
            <div className="mb-3 p-3 justify-content-center d-flex">
              <img
                width="100%"
                src={getImageURL({
                  path: form?.relationModule?.options?.bg,
                  type: "showcase/brand/bg",
                  size: "full",
                })}
              />
            </div>
          )}
        </Col>
      </Row>

      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <span className="text-muted fs-08 me-3">
          Данные применяться в течении 1 мин
        </span>
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default DeliveryPointForm;
