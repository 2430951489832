import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IoAdd,
  IoCloseOutline,
  IoEllipsisVertical,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { deleteCity, getCities } from "../../services/city";

const Cities = () => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state.brand.active);
  const inputRef = createRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [cities, setCities] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams.get("text"), searchParams.get("size")]);

  const cityColumns = [
    {
      name: "Название",
      selector: "title",
      cell: (row) => (
        <Link to={"/options/affiliates/city/" + row.id} className="me-2">
          {row?.options?.alias ? (
            <>
              <p>{row?.options?.alias}</p>
              <p className="text-muted fs-09">{row.title}</p>
            </>
          ) : (
            row.title
          )}
        </Link>
      ),
    },
    {
      width: "100px",
      name: "Филиалы",
      align: "center",
      cell: (row) =>
        row?.relationCities?.length > 0 ? (
          <Badge bg="success">{row.relationCities.length}</Badge>
        ) : (
          <Badge bg="secondary">Нет</Badge>
        ),
    },
    {
      width: "120px",
      name: "Статус",
      align: "center",
      selector: "comment",
      cell: (row) => {
        return row.status === 0 ? (
          <Badge bg="secondary">Не работает</Badge>
        ) : (
          <Badge bg="success">В работе</Badge>
        );
      },
    },
    {
      width: "80px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle
            as={React.forwardRef(({ children, onClick }, ref) => (
              <a
                ref={ref}
                className="py-0"
                onClick={(e) => {
                  e.preventDefault();
                  onClick(e);
                }}
              >
                <IoEllipsisVertical size={20} />
              </a>
            ))}
          />
          <Dropdown.Menu align="end">
            <Dropdown.Item
              as={Link}
              disabled={selected.length > 0}
              to={"/options/affiliates/city/" + row.id}
            >
              {t("Изменить")}
            </Dropdown.Item>
            <Dropdown.Item
              className="text-danger"
              disabled={selected.length > 0}
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: row.id })
              }
            >
              {t("Удалить")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];
  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0 ? `Выбрано ${selected.length}` : "Города"}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <Link to="create" className="btn-primary-outline me-3">
              <IoAdd size={18} />
            </Link>
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <Row>
          <Col md={9}>
            <Input
              ref={inputRef}
              placeholder={t("Найти")}
              className="w-100"
              onChange={(e) => {
                if (e.length > 0) {
                  searchParams.set("text", e);
                } else {
                  searchParams.delete("text");
                }
                setSearchParams(searchParams);
              }}
              rightIcon={() => <IoSearchOutline size={22} />}
              value={searchParams.get("text") ?? ""}
              rightIconClick={() => onSearch()}
              onKeyDown={(e) => e === "Enter" && onSearch()}
            />
            {searchParams.get("text")?.length > 0 && (
              <Button
                className="btn-light ms-3"
                onClick={() => {
                  searchParams.delete("text");
                  setSearchParams(searchParams);
                  onSearch();
                  if (inputRef.current) {
                    inputRef.current.value = "";
                  }
                }}
              >
                <IoCloseOutline size={22} />
              </Button>
            )}
          </Col>
          <Col md={3}>
            <Select
              classNameContainer="w-100"
              label="Показать"
              data={[
                { title: "25", value: "" },
                { title: "50", value: 50 },
                { title: "Все", value: 1000 },
              ]}
              value={
                searchParams.get("size") ? Number(searchParams.get("size")) : ""
              }
              onClick={(e) => {
                if (e.value > 0) {
                  searchParams.set("size", e.value);
                } else {
                  searchParams.delete("size");
                }
                setSearchParams(searchParams);
                onSearch();
              }}
            />
          </Col>
        </Row>
      </>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    getCities(searchParams)
      .then((res) =>
        setCities((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }))
      )
      .finally(() => setCities((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand]);

  const onDelete = useCallback((id) => {
    deleteCity(id)
      .then((res) => {
        NotificationManager.success("Город успешно удален");
        setModalDelete({ show: false, id: false });
        getData();
      })
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteCity(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные города успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  if (cities.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Города" />
      <DataTable
        columns={cityColumns}
        onChange={(items) => setSelected(items)}
        data={cities.items}
        header={header}
        selectable
        pagination={cities.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className="me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить город(-ы)?
      </CustomModal>
    </>
  );
};

export default Cities;
