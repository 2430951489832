import React, { useCallback } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Select from "../../components/UI/Select";
import { createZone } from "../../services/zone";
import { IoChevronBackOutline } from "react-icons/io5";
import { HuePicker } from "react-color";

const statusData = [
  {
    title: "В работе",
    value: 1,
  },
  {
    title: "Отключено",
    value: 0,
  },
];

const CreateZone = () => {
  const navigate = useNavigate();
  const affiliate = useSelector((state) => state.affiliate.active);
  const affiliates = useSelector((state) => state.affiliate.data);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onSubmit",
    defaultValues: {
      time: 60,
      status: 1,
      price: 0,
      priceFree: 0,
      minPrice: 0,
      affiliateId: affiliate?.id ?? null,
      options: {
        freeStatus: true,
        distance: true,
      },
    },
  });

  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    data.data = JSON.parse(data.data);
    createZone(data)
      .then(() => {
        NotificationManager.success("Зона доставки успешно добавлена");
        navigate(-1);
      })
      .catch((error) =>
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      );
  }, []);

  if (!data) {
    return (
      <>
        <Meta title="Зона доставки" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Такой зоны доставки нет</h3>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Создание зоны доставки" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/affiliates/zones"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={handleSubmit(onSubmit)}>Сохранить изменения</Button>
      </div>
      <h3 className="mb-4">Создание зоны доставки</h3>
      <Row>
        <Col md={7}>
          <Input
            className="mb-3"
            label="Название"
            name="title"
            errors={errors}
            register={register}
            validation={{ required: "Обязательное поле" }}
          />
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Цена доставки"
                  name="price"
                  type="number"
                  min={0}
                  errors={errors}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Мин сумма заказа"
                  name="minPrice"
                  type="number"
                  min={0}
                  errors={errors}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-4">
                <Input
                  label="Бесплатная доставка"
                  name="priceFree"
                  type="number"
                  min={0}
                  errors={errors}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
                <div>
                  <Form.Check className="mt-3 d-inline-block">
                    <Form.Check.Input
                      type="checkbox"
                      className="btn-sm"
                      id="freeStatus"
                      {...register("options.freeStatus")}
                    />
                    <Form.Check.Label
                      htmlFor="freeStatus"
                      className="ms-2 fs-09"
                    >
                      Включить бесплатную доставку
                    </Form.Check.Label>
                  </Form.Check>
                </div>
                <Form.Check className="mt-3 d-inline-block">
                  <Form.Check.Input
                    type="checkbox"
                    className="btn-sm"
                    id="distance"
                    defaultChecked={data?.options?.distance}
                    onChange={(e) =>
                      setValue("options.distance", e.target.checked)
                    }
                  />
                  <Form.Check.Label htmlFor="distance" className="ms-2 fs-09">
                    Показать время доставки
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  label="Время доставки"
                  name="time"
                  type="number"
                  min={0}
                  errors={errors}
                  register={register}
                  validation={{ required: "Обязательное поле" }}
                />
              </div>
            </Col>
            <Col md={6}>
              <Select
                label="Статус"
                onClick={(e) => setValue("status", e.value)}
                value={data?.status}
                data={statusData}
              />
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Select
                  label="Филиал"
                  onClick={(e) => setValue("affiliateId", e.value)}
                  value={data?.affiliateId}
                  data={
                    affiliates?.length > 0
                      ? affiliates.map((e) => ({ title: e.full, value: e.id }))
                      : []
                  }
                />
              </div>
            </Col>
            <Col md={12}>
              <h5 className="mb-3 fs-09">Цвет</h5>
              <HuePicker
                className="w-100"
                color={data?.color ?? ""}
                onChangeComplete={(e) => setValue("color", e.hex)}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreateZone;
