import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Col, Dropdown, Form, Row, Collapse, Card } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoAdd, IoChevronBackOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import PreviewImages from "../../components/PreviewImages";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { currencyData, timezones, work } from "../../helpers/all";
import useDebounce from "../../hooks/useDebounce";
import { editAffiliate, getAffiliate } from "../../services/affiliate";
import { editBrand, getBrand } from "../../services/brand";
import { getDadataStreets } from "../../services/dadata";
import { getModule } from "../../services/module";
import { setAffiliates } from "../../store/reducers/affiliateSlice";
import { updateBrand } from "../../store/reducers/brandSlice";

const EditBrand = () => {
  const { brandId } = useParams();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      title: null,
      desc: null,
    },
  });

  const data = useWatch({ control });

  useLayoutEffect(() => {
    getBrand(brandId)
      .then((res) => res && reset(res))
      .finally(() => setLoading(false));
  }, [brandId]);

  const onSubmit = useCallback((data) => {
    editBrand(data)
      .then((res) => {
        NotificationManager.success("Бренд успешно изменен");
        if (res) {
          dispatch(updateBrand(res));
        }
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Редактировать бренд" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/brands"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={handleSubmit(onSubmit)}>Сохранить изменения</Button>
      </div>
      <h3 className="mb-4">Редактирование бренд</h3>
      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <h5 className="mb-4">Основное</h5>
              <div className="mb-4">
                <Input
                  errors={errors}
                  label="Название"
                  name="title"
                  placeholder="Введите название"
                  register={register}
                  validation={{
                    maxLength: { value: 150, message: "Максимум 150 символов" },
                  }}
                />
              </div>

              <div className="mb-4">
                <Textarea
                  label="Описание"
                  name="desc"
                  placeholder="Введите описание"
                  errors={errors}
                  rows={4}
                  register={register}
                  validation={{
                    maxLength: {
                      value: 1500,
                      message: "Максимум 1500 символов",
                    },
                  }}
                />
              </div>

              <Form.Check className="mb-4">
                <Form.Check.Input
                  type="checkbox"
                  name="status"
                  id="status"
                  defaultChecked={!!data?.status}
                  {...register("status")}
                />
                <Form.Check.Label htmlFor="status" className="ms-2">
                  Бренд работает
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col md={6}>
              <h5 className="mb-2">Токен</h5>
              <p className="fs-09 mb-4">{data?.token}</p>
              <h5 className="mb-2 mt-3">Валюта</h5>
              <p className="text-muted fs-09 mb-4">
                С какой валютой работает данный филиал
              </p>
              {currencyData?.length > 0 &&
                currencyData.map((e) => (
                  <div>
                    <Form.Check className="my-2">
                      <Form.Check.Input
                        type="checkbox"
                        value={e.currency}
                        id={"currency-" + e.currency}
                        {...register("options.currency")}
                      />
                      <Form.Check.Label
                        htmlFor={"currency-" + e.currency}
                        className="ms-2"
                      >
                        {e.title} ({e.symbol})
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                ))}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditBrand;
