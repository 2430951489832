import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IoAdd,
  IoCloseOutline,
  IoCreateOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { deleteUser, getUsers } from "../../services/user";

const Users = () => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state.brand.active);
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();
  const [users, setUsers] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const [statistic, setStatistic] = useState({
    now: 0,
    activity: 0,
    new: 0,
    newProcent: 0,
  });

  const userColumns = [
    {
      name: "Имя",
      selector: "firstName",
      size: 2,
      cell: (row) => row.firstName ?? "Не указано",
    },
    {
      name: "Номер телефона",
      selector: "phone",
      size: 2,
    },
    {
      name: "Email",
      selector: "email",
    },
    {
      width: "80px",
      name: "Заказы",
      selector: "order",
    },
    {
      width: "80px",
      name: "Сумма",
      selector: "total",
    },
    {
      width: "80px",
      name: "Баллы",
      selector: "point",
    },
    {
      width: "40px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <Link
          disabled={selected.length > 0}
          to={"/user/" + row.id}
          className="me-2"
        >
          <IoCreateOutline size={22} />
        </Link>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getUsers(searchParams)
      .then((res) => {
        setUsers((prev) => ({
          ...prev,
          loading: false,
          ...res.users,
        }));
        setStatistic(res.statistic);
      })
      .finally(() => setUsers((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams, brand]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `${t("Выбрано")} ${selected.length}`
                : t("Пользователи")}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <Link to="/users/create" className="btn-primary-outline me-3">
              <IoAdd size={18} />
            </Link>
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label={t("Статус")}
                data={[
                  { title: "Все", value: "" },
                  { title: "Не подтвержденные", value: "blocked" },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате регистрации: новые", value: "datenew" },
                  { title: "По дате регистрации: старые", value: "dateold" },
                  { title: "Заказов: больше", value: "ordermore" },
                  { title: "Заказов: меньше", value: "orderless" },
                ]}
                value={searchParams.get("sort") ?? "datenew"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={6}>
              <Input
                ref={inputRef}
                placeholder={t("Найти")}
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                value={searchParams.get("text") ?? ""}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete, t]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const onDeleteSelected = useCallback(() => {
    deleteUser(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success(
          t("Выбранные пользователи успешно удалены")
        );
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  if (users.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title={t("Пользователи")} />
      <Swiper
        speed={750}
        spaceBetween={10}
        className="pb-4 pt-2 px-2"
        slidesPerView={"auto"}
        freeMode={true}
        breakpoints={{
          576: {
            slidesPerView: "auto",
          },
          768: {
            slidesPerView: "auto",
          },
          992: {
            slidesPerView: 3,
          },
        }}
      >
        <SwiperSlide>
          <Card className="h-100" body>
            <Row className="d-flex align-items-center">
              <Col>
                <h5 className="mb-0 fw-6 h6">{t("Онлайн")}</h5>
              </Col>
              <Col>
                <Select
                  title="Выберите период"
                  className="fs-09 select-sm"
                  data={[
                    {
                      title: "День",
                      value: "day",
                    },
                    {
                      title: "Неделя",
                      value: "week",
                    },
                    {
                      title: "Месяц",
                      value: "month",
                    },
                  ]}
                  value={searchParams.get("stat") ?? "day"}
                  onClick={(e) => {
                    searchParams.set("stat", e.value);
                    setSearchParams(searchParams);
                    onSearch();
                  }}
                />
              </Col>
            </Row>
            <div>
              <h2 className="m-0">{statistic.now}</h2>
              <div className="mt-2">
                <span className="me-2">
                  {statistic.totalProcent > 0 ? (
                    <img src="/images/stat-plus.svg" />
                  ) : (
                    <img src="/images/stat-minus.svg" />
                  )}
                </span>
                <small className="fw-6">
                  {statistic.totalProcent > 0
                    ? "+" + statistic.totalProcent
                    : statistic.totalProcent}
                  %{" "}
                  <span className="light-gray">
                    {t(
                      `за ${
                        searchParams.get("stat") == "month"
                          ? "месяц"
                          : searchParams.get("stat") == "week"
                          ? "неделя"
                          : "день"
                      }`
                    )}
                  </span>
                </small>
              </div>
            </div>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="h-100" body>
            <Row className="d-flex align-items-center">
              <Col>
                <h5 className="mb-0 fw-6 h6">{t("Новые")}</h5>
              </Col>
              <Col>
                <Select
                  title="Выберите период"
                  className="fs-09 select-sm"
                  data={[
                    {
                      title: "День",
                      value: "day",
                    },
                    {
                      title: "Неделя",
                      value: "week",
                    },
                    {
                      title: "Месяц",
                      value: "month",
                    },
                  ]}
                  value={searchParams.get("stat") ?? "day"}
                  onClick={(e) => {
                    searchParams.set("stat", e.value);
                    setSearchParams(searchParams);
                    onSearch();
                  }}
                />
              </Col>
            </Row>
            <div>
              <h2 className="m-0">{statistic.new}</h2>
              <div className="mt-2">
                <span className="me-2">
                  {statistic.newProcent > 0 ? (
                    <img src="/images/stat-plus.svg" />
                  ) : (
                    <img src="/images/stat-minus.svg" />
                  )}
                </span>
                <small className="fw-6">
                  {statistic.newProcent > 0
                    ? "+" + statistic.newProcent
                    : statistic.newProcent}
                  %{" "}
                  <span className="light-gray">
                    {t(
                      `за ${
                        searchParams.get("stat") == "month"
                          ? "месяц"
                          : searchParams.get("stat") == "week"
                          ? "неделя"
                          : "день"
                      }`
                    )}
                  </span>
                </small>
              </div>
            </div>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card className="h-100" body>
            <Row className="d-flex align-items-center">
              <Col>
                <h5 className="mb-0 fw-6 h6">{t("Все")}</h5>
              </Col>
              <Col>
                <Select
                  title="Выберите фильтр"
                  className="fs-09 select-sm"
                  classNameContainerMenu="zindex"
                  data={[
                    {
                      title: "Все",
                      value: "",
                    },
                    {
                      title: "Android",
                      value: "android",
                    },
                    {
                      title: "iOS",
                      value: "ios",
                    },
                  ]}
                  value={searchParams.get("filter") ?? null}
                  onClick={(e) => {
                    searchParams.set("filter", e.value);
                    setSearchParams(searchParams);
                    onSearch();
                  }}
                />
              </Col>
            </Row>
            <div>
              <h2 className="m-0">{statistic.all}</h2>
            </div>
          </Card>
        </SwiperSlide>
      </Swiper>

      <DataTable
        columns={userColumns}
        onChange={(items) => setSelected(items)}
        data={users.items}
        header={header}
        selectable
        pagination={users.pagination}
      />
      <CustomModal
        title={`${t("Удаление")} ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              {t("Отмена")}
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              {t("Удалить")}
            </Button>
          </>
        }
      >
        {t("Вы точно хотите удалить пользователя?")}
      </CustomModal>
    </>
  );
};

export default Users;
