import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { IoAdd, IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Online from "../../components/Online";
import Button from "../../components/UI/Button";
import Loader from "../../components/UI/Loader";
import CustomModal from "../../components/utils/CustomModal";
import socket from "../../config/socket";
import { convertColor } from "../../helpers/convertColor";
import { isRole } from "../../helpers/member";
import { deleteMember, getMembers } from "../../services/member";

const Members = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const brand = useSelector((state) => state?.brand?.active);
  const [members, setMembers] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });

  const memberColumns = [
    {
      name: "Имя",
      selector: "firstName",
      cell: (row) => (
        <Link to={"/options/member/" + row.id}>
          <p>{row.firstName}</p>
          <Online data={row} />
        </Link>
      ),
    },
    {
      name: "Номер телефона",
      selector: "phone",
      cell: (row) =>
        row?.phone ? (
          <Link to={"/options/member/" + row.id}>{row.phone}</Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Email",
      selector: "email",
      cell: (row) =>
        row?.email ? (
          <Link to={"/options/member/" + row.id}>{row.email}</Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Отдел",
      selector: "groupRole",
      align: "center",
      cell: (row) => {
        let role = isRole(row);
        return (
          <Link
            to={"/options/member/" + row.id}
            className="role"
            style={
              role?.group?.color && {
                color: role.group.color,
                backgroundColor: convertColor(role.group.color, 0.08),
              }
            }
          >
            {role?.group?.title ?? "Отдел не указан"}
          </Link>
        );
      },
    },
    {
      name: "Должность",
      selector: "role",
      align: "center",
      cell: (row) => {
        let role = isRole(row);
        return (
          <Link
            to={"/options/member/" + row.id}
            className="role"
            style={
              role?.role?.color && {
                color: role.role.color,
                backgroundColor: convertColor(role.role.color, 0.08),
              }
            }
          >
            {role?.role?.title ?? "Должности нет"}
          </Link>
        );
      },
    },
    {
      width: "50px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <Link to={"/options/member/" + row.id} className="me-2">
          <IoCreateOutline size={22} />
        </Link>
      ),
    },
  ];
  const header = useMemo(() => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h5 className="fw-7">
            {selected.length > 0 ? `Выбрано ${selected.length}` : "Сотрудники"}
          </h5>
        </div>
        <div className="d-flex align-items-center">
          <Link to="create" className="btn-primary-outline me-3">
            <IoAdd size={18} />
          </Link>
          <Button
            disabled={selected.length === 0}
            className="btn-light"
            onClick={() => setModalDelete({ show: true, id: false })}
          >
            <IoTrashOutline size={18} />
          </Button>
        </div>
      </div>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    getMembers(searchParams)
      .then((res) => {
        setMembers((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }));
        brand?.id && socket.emit("member/list");
      })
      .catch(() => setMembers((prev) => ({ ...prev, loading: false })));
  }, [searchParams, brand]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), brand]);

  useLayoutEffect(() => {
    if (brand?.id) {
      socket.on("member/list", (data) => {
        if (Array.isArray(data)) {
          setMembers(({ items }) => ({
            loading: false,
            items: Array.isArray(items)
              ? items.map((e) => {
                  let info = data.find(
                    (e2) => String(e2.memberId) === String(e.id)
                  );

                  e.online = !!info ? info?.end ?? "now" : false;

                  return e;
                })
              : [],
          }));
        }
      });
      return () => {
        socket.off("member/list");
      };
    }
  }, [brand]);

  const clickDelete = (id) => {
    deleteMember(id).then(() => getData(page));
    setModalDelete({ isShow: false, id: false });
  };

  if (members.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Сотрудники" />
      <DataTable
        columns={memberColumns}
        onChange={(items) => setSelected(items)}
        data={members.items}
        header={header}
        selectable
        linkPagination="/options/members/"
        statusBackground="member"
        pagination={members.pagination}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить категорию?
      </CustomModal>
    </>
  );
};

export default Members;
