import React, { useEffect, useState } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Info from "../../components/UI/Info";
import faqData from "./faqData.json";

const FaqView = () => {
  const { faqId, categoryId } = useParams();
  const category = faqData.find((e) => e.id === categoryId);
  const data = category.data.find((e) => e.id === faqId);

  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        if (data?.html) {
          const response = await fetch(`/html/${data.html}`);
          if (!response.ok) {
            throw new Error("Сетевой ответ не был ok");
          }
          const text = await response.text();
          setHtmlContent(text);
        }
      } catch (error) {
        console.error("Ошибка при загрузке HTML:", error);
      }
    };

    fetchHtmlContent();
  }, []);

  if (!data?.id) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такой новости нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={data?.title ?? "Статья"} />
      <div>
        <Link
          to={"/faq/" + categoryId}
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h1 className="mb-2">{data.title}</h1>
      <p className="mb-4 text-muted fs-09">Добавлено {data.date}</p>
      <div
        className="faq-content"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </>
  );
};

export default FaqView;
