import AcquiringForm from "./AcquiringForm";
import AppForm from "./AppForm";
import EmailForm from "./EmailForm";
import FrontpadForm from "./FrontpadFrom";
import IikoForm from "./IikoForm";
import PublicForm from "./PublicForm";
import RedirectForm from "./RedirectForm";
import RKeeperForm from "./rkeeperForm";
import SiteForm from "./SiteForm";
import SmspilotForm from "./SmspilotForm";
import TelegramForm from "./TelegramForm";
import PointForm from "./PointForm";
import BrandForm from "./BrandForm";
import OneCForm from "./OneCForm";
import ApiForm from "./ApiForm";
import LanguageForm from "./LanguageForm";
import KioskForm from "./KioskForm";
import PrestoForm from "./PrestoForm";
import DeliveryPointForm from "./DeliveryPointForm";
import YandexDeliveryForm from "./YandexDelivery";
import VkForm from "./VkForm";

const FormModule = (props) => {
  if (!props?.data?.name) {
    return "Такого модуля не существует";
  }
  const forms = {
    iiko: <IikoForm {...props} />,
    rkeeper: <RKeeperForm {...props} />,
    frontpad: <FrontpadForm {...props} />,
    onec: <OneCForm {...props} />,
    app: <AppForm {...props} />,
    site: <SiteForm {...props} />,
    brand: <BrandForm {...props} />,
    acquiring: <AcquiringForm {...props} />,
    public: <PublicForm {...props} />,
    email: <EmailForm {...props} />,
    smspilot: <SmspilotForm {...props} />,
    redirect: <RedirectForm {...props} />,
    telegram: <TelegramForm {...props} />,
    vk: <VkForm {...props} />,
    point: <PointForm {...props} />,
    api: <ApiForm {...props} />,
    language: <LanguageForm {...props} />,
    kiosk: <KioskForm {...props} />,
    presto: <PrestoForm {...props} />,
    deliverypoint: <DeliveryPointForm {...props} />,
    yandexdelivery: <YandexDeliveryForm {...props} />,
  };
  return forms[props.data.name];
};
export default FormModule;
