import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";
import Loader from "../../components/UI/Loader";
import faqData from "./faqData.json";

const Faq = () => {
  if (faqData.length === 0) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="FAQ" />
      <Row>
        {faqData.map((e) => (
          <Col md={4} lg={4}>
            <Link to={"/faq/" + e.id} state={e}>
              <Card body className="faq">
                <img src={e.image} className="faq-image" />
                <h4 className="fw-7 mb-2">{e.title}</h4>
                <p className="fs-09">{e.desc}</p>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Faq;
