const statusTypes = [
  {
    text: "Предзаказ",
    id: "reservation",
    className: "status-order-reservation",
  },
  { text: "Новый", id: "new", className: "status-order-new" },
  { text: "Готовится", id: "preparing", className: "status-order-preparing" },
  {
    text: "На выдаче",
    id: "prepared",
    className: "status-order-prepared",
  },
  { text: "Доставка", id: "delivery", className: "status-order-delivery" },
  { text: "Завершен", id: "done", className: "status-order-done" },
  {
    text: "В обработке",
    id: "processing",
    className: "status-order-processing",
  },
  { text: "Отменен", id: "canceled", className: "status-order-canceled" },
];

const typeTypes = [
  {
    text: "Сайт",
    id: "site",
    className: "site-order",
    icon: "/images/source/site.svg",
  },
  {
    text: "Приложение",
    id: "app",
    className: "app-order",
    icon: "/images/source/app.svg",
  },
  {
    text: "ВКонтакте",
    id: "vk",
    className: "vk-order",
    icon: "/images/source/vk.svg",
  },
];

const deliveryTypes = [
  {
    text: "Самовывоз",
    id: "affiliate",
    className: "delivery-order-1",
    icon: "/images/delivery/pickup.svg",
  },
  {
    text: "Доставка",
    id: "delivery",
    className: "status-order-2",
    icon: "/images/delivery/delivery.svg",
  },
  {
    text: "В заведении",
    id: "cafe",
    className: "status-order-3",
    icon: "/images/delivery/cafe.svg",
  },
];

const paymentTypes = [
  {
    text: "Картой при получении",
    id: 1,
    type: "card",
    className: "payment-order-1",
    icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5 4.375H2.5C2.15482 4.375 1.875 4.65482 1.875 5V15C1.875 15.3452 2.15482 15.625 2.5 15.625H17.5C17.8452 15.625 18.125 15.3452 18.125 15V5C18.125 4.65482 17.8452 4.375 17.5 4.375Z" stroke="#3366FF" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M13.125 13.125H15.625" stroke="#3366FF" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9.375 13.125H10.625" stroke="#3366FF" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.875 7.57031H18.125" stroke="#3366FF" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    ,
  },
  {
    text: "Наличными",
    id: 2,
    type: "cash",
    className: "payment-order-2",
    icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.875 15.625C6.32134 15.6214 10.7483 16.2102 15.0392 17.3758C15.645 17.5408 16.25 17.0908 16.25 16.4625V15.625M3.125 3.75V4.375C3.125 4.54076 3.05915 4.69973 2.94194 4.81694C2.82473 4.93415 2.66576 5 2.5 5H1.875M1.875 5V4.6875C1.875 4.17 2.295 3.75 2.8125 3.75H16.875M1.875 5V12.5M16.875 3.75V4.375C16.875 4.72 17.155 5 17.5 5H18.125M16.875 3.75H17.1875C17.705 3.75 18.125 4.17 18.125 4.6875V12.8125C18.125 13.33 17.705 13.75 17.1875 13.75H16.875M1.875 12.5V12.8125C1.875 13.0611 1.97377 13.2996 2.14959 13.4754C2.3254 13.6512 2.56386 13.75 2.8125 13.75H3.125M1.875 12.5H2.5C2.66576 12.5 2.82473 12.5658 2.94194 12.6831C3.05915 12.8003 3.125 12.9592 3.125 13.125V13.75M16.875 13.75V13.125C16.875 12.9592 16.9408 12.8003 17.0581 12.6831C17.1753 12.5658 17.3342 12.5 17.5 12.5H18.125M16.875 13.75H3.125M12.5 8.75C12.5 9.41304 12.2366 10.0489 11.7678 10.5178C11.2989 10.9866 10.663 11.25 10 11.25C9.33696 11.25 8.70107 10.9866 8.23223 10.5178C7.76339 10.0489 7.5 9.41304 7.5 8.75C7.5 8.08696 7.76339 7.45107 8.23223 6.98223C8.70107 6.51339 9.33696 6.25 10 6.25C10.663 6.25 11.2989 6.51339 11.7678 6.98223C12.2366 7.45107 12.5 8.08696 12.5 8.75ZM15 8.75H15.0067V8.75667H15V8.75ZM5 8.75H5.00667V8.75667H5V8.75Z" stroke="#00AB55" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    ,
  },
  {
    text: "Онлайн",
    id: 3,
    type: "online",
    className: "payment-order-3",
    icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 17.5C11.6625 17.4999 13.2779 16.9477 14.5925 15.93C15.9072 14.9124 16.8466 13.4869 17.2633 11.8775M10 17.5C8.33751 17.4999 6.72212 16.9477 5.40748 15.93C4.09284 14.9124 3.1534 13.4869 2.73667 11.8775M10 17.5C12.0708 17.5 13.75 14.1417 13.75 10C13.75 5.85833 12.0708 2.5 10 2.5M10 17.5C7.92917 17.5 6.25 14.1417 6.25 10C6.25 5.85833 7.92917 2.5 10 2.5M17.2633 11.8775C17.4175 11.2775 17.5 10.6483 17.5 10C17.5021 8.71009 17.1699 7.44166 16.5358 6.31833M17.2633 11.8775C15.041 13.1095 12.541 13.754 10 13.75C7.365 13.75 4.88917 13.0708 2.73667 11.8775M2.73667 11.8775C2.57896 11.2641 2.49944 10.6333 2.5 10C2.5 8.6625 2.85 7.40583 3.46417 6.31833M10 2.5C11.3302 2.49945 12.6366 2.8528 13.7852 3.5238C14.9337 4.19481 15.8831 5.15931 16.5358 6.31833M10 2.5C8.6698 2.49945 7.3634 2.8528 6.21484 3.5238C5.06628 4.19481 4.11692 5.15931 3.46417 6.31833M16.5358 6.31833C14.7214 7.88994 12.4004 8.75345 10 8.75C7.50167 8.75 5.21667 7.83333 3.46417 6.31833" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    ,
  },
  { text: "Расчетный счет", id: 4, type: "ip", className: "payment-order-4" },
];

const statusData = (data) => {
  for (const [key, value] of Object.entries(data)) {
    if (!value) {
      break;
    }
  }
  let info = statusTypes.find((e) => e.id === data) ?? statusTypes[0];
  return info;
};

const typeData = (value) => {
  let info = typeTypes.find((e) => e.id === value) ?? typeTypes[0];
  return info;
};

const deliveryData = (value) => {
  let info = deliveryTypes.find((e) => e.id === value) ?? deliveryTypes[0];
  return info;
};

const paymentData = (value) => {
  let info = paymentTypes.find((e) => e.type === value) ?? paymentTypes[0];
  return info;
};

export {
  paymentData,
  deliveryData,
  statusData,
  typeData,
  statusTypes,
  deliveryTypes,
  paymentTypes,
  typeTypes,
};
